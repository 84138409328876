(function textScript($, Drupal) {
  Drupal.behaviors.table = {
    attach(context) {
      // Run on window load instead of on DOM Ready in case images or other scripts affect element widths
$(window).on('load', function() {
	// Check all tables. You may need to be more restrictive.
	$('table').each(function() {
		var element = $(this);
		// Create the wrapper element
		var scrollWrapper = $('<div />', {
			'class': 'scrollable',
			'html': '<div />' // The inner div is needed for styling
		}).insertBefore(element);
		// Store a reference to the wrapper element
		element.data('scrollWrapper', scrollWrapper);
		// Move the scrollable element inside the wrapper element
		element.appendTo(scrollWrapper.find('div'));
		// Check if the element is wider than its parent and thus needs to be scrollable
		if (element.find('tbody').outerWidth() > element.parent().outerWidth()) {
      element.data('scrollWrapper').addClass('has-scroll');
		}
		// When the viewport size is changed, check again if the element needs to be scrollable
		$(window).on('resize orientationchange', function() {
			if (element.find('tbody').outerWidth() > element.parent().outerWidth()) {
        element.data('scrollWrapper').addClass('has-scroll');
			} else {
        element.data('scrollWrapper').removeClass('has-scroll');
			}
		});
	});
});
    },
  };
}(jQuery, Drupal));
